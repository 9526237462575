<template>
  <div>
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>
<script>
import videojs from "video.js";

export default {
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },

  mounted() {
    this.player = this.$video(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {}
    );
    setInterval(() => {
      if (this.$refs.videoPlayer.paused) {
        this.$refs.videoPlayer.play();
      }
    }, 60000);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
