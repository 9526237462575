<template>
  <div>
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="6.25rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        console.log(newData)
        this.options = {
          title: {
            text: "",
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "#042026",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC",
              },
            },
          },
          legend: {
            data: ["客流", "销量"],
            textStyle: {
              color: ["#5EF46F", "#333"],
              fontSize: "0.25rem",
            },
            top: "0%",
            right:10
          },
          grid: {
            x: "8%",
            width: "88%",
            y: "10%",
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: [
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4",
                },
              },

              axisLabel: {
                formatter: "{value} ",
              },
            },
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4",
                },
              },
              axisLabel: {
                formatter: "{value} ",
              },
            },
          ],
          series: [
            {
              name: "贯通率",
              type: "line",
              smooth: true,
              // showAllSymbol: true,
              symbol: "none",
              // symbolSize: 8,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#F02FC2",
                },
              },
              data: newData.rateData,
            },
            {
              name: "客流",
              type: "line",
              symbol: "none",
              barWidth: 5,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#18E7FC" },
                    { offset: 1, color: "#18E7FC" },
                  ]),
                },
              },
              areaStyle: {
                opacity: 0.8,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(24, 231, 252, 1)'
                }, {
                    offset: 0.6,
                    color: 'rgba(161, 229, 253, 0.6)'
                }, {
                    offset: 1,
                    color: 'rgba(211, 239, 249, 0)'
                }])
            },
              data: newData.barData,
            },
            {
              name: "销量",
              type: "line",
              barGap: "-100%",
              barWidth: 5,
              symbol: "none",
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#5EF46F" },
                    { offset: 0.2, color: "#5EF46F" },
                    { offset: 1, color: "#5EF46F" },
                  ]),
                },
              },
              areaStyle: {
                opacity: 0.8,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(153, 249, 164, 1)'
                }, {
                    offset: 0.6,
                    color: 'rgba(153, 249, 164, 0.5)'
                }, {
                    offset: 1,
                    color: 'rgba(153, 249, 164, 0.1)'
                }])
            },
              z: -12,
              data: newData.lineData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss">
#bottomLeftChart {
  .bg-color-black {
    background-color: rgba(19, 25, 47, 0);
  }
}
</style>
