<template>
  <!-- 轮播图开始 -->
  <div class="swiper">
    <swiper :options="swiperOption" ref="mySwiper" v-if="swiperData.length > 1">
      <!-- 轮播的图片 -->
      <swiper-slide v-for="(item, index) in swiperData" :key="index">
        <img :src="item" :style="{ width: imgWidth, height: imgHeight }" />
      </swiper-slide>
    </swiper>
    <swiper
      :options="swiperOption1"
      ref="mySwiper"
      v-if="swiperData.length == 1"
    >
      <!-- 轮播的图片 -->
      <swiper-slide v-for="(item, index) in swiperData" :key="index">
        <img :src="item" :style="{ width: imgWidth, height: imgHeight }" />
      </swiper-slide>
    </swiper>
  </div>
  <!-- 轮播图结束 -->
</template>

<script>
export default {
  props: {
    swiperData: {
      default: [],
      type: Array,
    },
    imgWidth: {
      default: "",
      type: String,
    },
    imgHeight: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      on_sale_image: [],
      swiperOption: {
        loop: true, // 设置图片循环
        autoplay: true, //设置可自动播放
        autoplayStopOnLast: true,
        speed: 4000, //自动播放的速度，每隔1秒滑动一次
      },
      swiperOption1: {
        loop: true, // 设置图片循环
        autoplay: false, //设置可自动播放
        autoplayStopOnLast: true,
        speed: 2000, //自动播放的速度，每隔1秒滑动一次
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
/* swiper轮播图样式设置开始 */
/* 修改分页小圆点的默认样式 */

.swiper {
  width: 100%;
}
.swiper img {
  width: 100%;
  height: 100%;
}
/* swiper轮播图样式设置结束 */
</style>
