<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import axios from "axios";
import Chart from "./chart.vue";
export default {
  data() {
    return {
      uuid: "",
      cdata: {
        category: [],
        lineData: [],
        barData: [],
        rateData: [],
      },
    };
  },
  components: {
    Chart,
  },
  mounted() {
    // this.setData();
    // this.getList();
    this.timer();
    this.init();
  },
  methods: {
    // 根据自己的业务情况修改
    // setData() {
    //   for (let i = 0; i < this.cdata.barData.length - 1; i++) {
    //     let rate = this.cdata.barData[i] / this.cdata.lineData[i];
    //     this.cdata.rateData.push(rate.toFixed(2));
    //   }
    // },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
    },
    getList() {
      axios
        .post("api/screen/order/report", {
          action: "sale.list",
          uuid: this.uuid,
          // uuid: "1e852fa1-95d2-3279-9146-a7b62fcb7df5",
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          this.data = data;

          this.cdata.category = this.data.map((c) => c.hour);
          this.cdata.lineData = this.data.map((c) => c.sales_money);
          this.cdata.barData = this.data.map((c) => c.flow_number);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
