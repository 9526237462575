<template>
  <div class="left3_1">
    <span
      class="fs-xl mx-2 ms_title"
      style="font-size:0.25rem"
      v-if="area1ShowType != 2"
      >{{
        area1ShowType == 4
          ? standBook[area1StandingType - 1].name
          : "检测项目占比"
      }}</span
    >
    <!--原来的图表-->
    <div
      id="left3_1_echarts"
      style="height:2.5rem;width:5.4rem;"
      v-if="area1ShowType == 1"
    ></div>
    <!--轮播广告-->
    <div
      style="width:5.6rem;height:2.6rem;margin:0 auto;overflow:hidden;"
      v-if="area1ShowType == 2"
    >
      <image-tip
        :swiperData="area1Image"
        :imgWidth="imgWidth"
        :imgHeight="imgHeight"
      />
    </div>
    <!--记录-->
    <div class="d-flex jc-center body-box" v-if="area1ShowType == 4">
      <dv-scroll-board
        :config="config"
        style="width:5.4rem;height:2.2rem;margin:0 auto;"
      />
      <!--<dv-scroll-board :config="config" style="width:4.45rem;height:4.1rem" />-->
    </div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import axios from "axios";
import { formatTime } from "@/utils/index.js";
import ImageTip from "@/components/echart/image";

export default {
  props: {
    area1Image: {
      default: [],
      type: Array,
    },
    area1StandingType: {
      default: 1,
      type: Number,
    },
    area1ShowType: {
      default: 1,
      type: Number,
    },
    area1_video: {
      default: "",
      type: String,
    },
    isInter: {
      type: Boolean,
      default: false,
    },
  },
  components: { ImageTip },
  data() {
    return {
      uuid: "",
      config: {},
      imgWidth: "5.6rem",
      imgHeight: "2.6rem",
      standBook: [
        { id: 1, name: "质检记录" },
        { id: 2, name: "进货记录" },
        { id: 3, name: "消杀记录" },
        { id: 4, name: "消毒记录" },
        { id: 5, name: "衡器检修" },
      ],
      standBookHead1: ["时间", "品名", "检测项目", "检测结果"],
      standBookHead2: ["时间", "进场品名", "商户", "进场数量"],
      standBookHead3: ["时间", "消毒区域", "消毒方式", "操作人"],
      standBookHead4: ["时间", "消毒区域", "消杀方式", "操作人"],
      standBookHead5: ["时间", "设备号", "商户", "设备厂家"],
    };
  },
  mixins: [echartMixins],
  mounted() {
    // this.timer();
    this.init();
    setInterval(() => {
      setTimeout(() => {
        this.getList();
      }, 0);
    }, 1800000);
  },
  methods: {
    destroyed() {
      window.onresize = null;
    },
    // timer() {
    //   return setInterval(() => {
    //     this.getList();
    //   }, 1800000);
    // },
    async init() {
      if (window.android && !this.isInter) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
    },
    getList() {
      if (this.area1ShowType == 1) {
        this.getRechartData();
      } else if (this.area1ShowType == 4) {
        this.getTableList();
      }
    },
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/screen/order/report", {
          action: "quality.cate.get",
          uuid: this.uuid,
          // uuid: "f8a9737f-edf7-3fd2-bc61-5318ca8705ff",
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          const isFont = this.$route.query.type;

          const target = data.map((c) => {
            const { c_name, rate } = c;
            return {
              name: c_name.length > 5 ? c_name.slice(0, 5) + "..." : c_name,
              value: rate,
            };
          });
          this.chart = this.$echarts.init(
            document.getElementById("left3_1_echarts")
          );
          this.chart.setOption({
            color: ["#10CFE7", "#FBA1AB", "#68D9B5", "#DAB0FF"],
            tooltip: {
              trigger: "item",
              // formatter: "{b}: {c} <br />({d}%)",
            },

            series: [
              {
                type: "pie",
                // radius: ["85%", "55%"],
                // radius: "60%",
                // center: ["50%", "55%"],
                data: target,
                label: {
                  normal: {
                    textStyle: {
                      fontSize:
                        isFont == 2
                          ? this.FontChart(0.32)
                          : this.FontChart(0.22),
                      // color: "#ffffff",
                      fontWeight: 400,
                    },
                    formatter: "{b} \n{c}%  ",
                  },
                  x: 0,
                },
              },
            ],
          });
        });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      return res * fontSize;
    },
    // 获取表格的数据
    getTableList() {
      axios
        .post("api/screen/order/report", {
          action: "standing.get",
          uuid: this.uuid,
          type: this.area1StandingType,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          const target = data.map((c) => {
            const { value1, value2, value3, value4, value5 } = c;
            let aa = value5 ? value4 + value5 : value4;
            let time = formatTime(value1 * 1000, "MM-dd");
            return [time, value2, value3, aa];
          });
          this.config.data = target;

          let tabHead =
            this.area1StandingType == 1
              ? this.standBookHead1
              : this.area1StandingType == 2
              ? this.standBookHead2
              : this.area1StandingType == 3
              ? this.standBookHead3
              : this.area1StandingType == 4
              ? this.standBookHead4
              : this.standBookHead5;

          this.config = {
            data: target,
            header: tabHead,
            rowNum: 5, //表格行数
            headerHeight: 35,
            headerBGC: "rgb(4, 7, 138, 0)", //表头
            oddRowBGC: "rgb(4, 7, 138, 0)", //奇数行
            evenRowBGC: "rgb(4, 7, 138, 0)", //偶数行
            align: ["center", "center", "center", "center", "center"],
          };
        });
    },
  },
};
</script>

<style lang="scss">
.left3_1 {
  padding: 0.5rem 0.2rem 0.3rem;
  height: 3.25rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  background: url("../../assets/list_bac.png") no-repeat;
  background-size: 100% 100%;
  .dv-scroll-board .header {
    border: 1px solid #4a60fb !important;
    color: #64d6ff !important;
    background: transparent !important;
  }

  .ms_title {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
  }

  .ms_title::before {
    content: " ";
    display: inline-block;
    width: 0.05rem;
    height: 0.15rem;
    margin-right: 0.1rem;
    background-color: #64ffa3;
  }
}
.left3_1 .body-box {
  overflow: hidden;
}
</style>
