<template>
  <div>
    <div class="right2">
      <span
        style="font-size: 0.25rem"
        class="mx-2 ms_title"
        v-if="area1ShowType != 2"
        >{{
          area1ShowType == 4
            ? standBook[area1StandingType - 1].name
            : "品类分布"
        }}</span
      >
      <div
        id="right2"
        style="height: 2.5rem; width: 100%; padding-top: 0.1rem"
        v-if="area1ShowType == 1"
      >
        >
      </div>
      <!--轮播广告-->
      <div
        style="width: 5.6rem; height: 2.6rem; margin: 0 auto; overflow: hidden"
        v-if="area1ShowType == 2"
      >
        <image-tip
          :swiperData="area1Image"
          :imgWidth="imgWidth"
          :imgHeight="imgHeight"
        />
      </div>
      <!--记录-->
      <div class="d-flex jc-center body-box" v-if="area1ShowType == 4">
        <dv-scroll-board
          :config="config"
          style="height: 2.2rem; margin: 0 auto"
        />
      </div>
    </div>
    <!-- <div class="right2" v-if="market_id == 2380">
      <param value="rtsp://admin:anxuan0571@x:554/h264/ch1/sub/av_stream" />
      <object
        width="100%"
        height="100%"
        events="false"
        pluginspage="http://www.videolan.org/"
        type="application/x-vlc-plugin"
      >
        <param name="volume" value="50" />
        <param name="autoPlay" value="true" />
        <param name="loop" value="true" />
        <param name="fullscreen" value="0" />
        <param name="controls" value="false" />
      </object>
    </div> -->
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import axios from "axios";
import { formatTime } from "@/utils/index.js";
import ImageTip from "@/components/echart/image";

import "videojs-flash";

import "video.js/dist/video-js.css";

export default {
  props: {
    area1Image: {
      default: [],
      type: Array,
    },
    area1StandingType: {
      default: 1,
      type: Number,
    },
    area1ShowType: {
      default: 1,
      type: Number,
    },
    area1_video: {
      default: "",
      type: String,
    },
    isInter: {
      type: Boolean,
      default: false,
    },
  },
  components: { ImageTip },
  data() {
    return {
      config: {},
      uuid: "",
      imgWidth: "5.6rem",
      imgHeight: "2.6rem",
      standBook: [
        { id: 1, name: "质检记录" },
        { id: 2, name: "进货记录" },
        { id: 3, name: "消杀记录" },
        { id: 4, name: "消毒记录" },
        { id: 5, name: "衡器检修" },
      ],
      standBookHead1: ["时间", "品名", "检测项目", "检测结果"],
      standBookHead2: ["时间", "进场品名", "商户", "进场数量"],
      standBookHead3: ["时间", "消毒区域", "消毒方式", "操作人"],
      standBookHead4: ["时间", "消毒区域", "消杀方式", "操作人"],
      standBookHead5: ["时间", "设备号", "商户", "设备厂家"],
      // playerOptions: {
      //   height: "260",
      //   language: "en",
      //   playbackRates: [0.5, 1.0, 1.5, 2.0],
      //   sources: [
      //     {
      //       type: "rtmp/mp4",
      //       src: "rtmp://58.200.131.2:1935/livetv/cctv1",
      //     },
      //   ],
      //   techOrder: ["flash"],
      //   autoplay: false,
      //   controls: true,
      //   // poster: "../../assets/images/video2.jpg",
      //   notSupportedMessage: "不支持的视频格式",
      // },
      playerOptions: {
        height: "260",
        language: "en",
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "rtsp/mp4",
            src:
              "rtsp://admin:anxuan0571@172.16.8.47:554/h264/ch1/sub/av_stream",
          },
        ],
        techOrder: ["flash"],
        autoplay: false,
        controls: true,
        // poster: "../../assets/images/video2.jpg",
        notSupportedMessage: "不支持的视频格式",
      },
    };
  },
  mixins: [echartMixins],
  mounted() {
    // this.timer();
    this.init();
    setInterval(() => {
      setTimeout(() => {
        this.getList();
      }, 0);
    }, 1800000);
   
  },
  methods: {
    // getFrame() {
    //   var url =
    //     "rtsp://admin:anxuan0571@172.16.8.47:554/h264/ch1/sub/av_stream";
    //   return "static/rtsp-frame.html?src=" + url;
    // },

    destroyed() {
      window.onresize = null;
    },

    timer() {
      return setInterval(() => {
        this.getList();
      }, 1800000);
    },
    async init() {
      if (window.android && !this.isInter) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
    },
    getList() {
      if (this.area1ShowType == 1) {
        this.getRechartData();
      } else if (this.area1ShowType == 4) {
        this.getTableList();
      }
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      return res * fontSize;
    },
    toLarge(a, b) {
      return b.money - a.money;
    },
    // 获取之前的图表的数据
    getRechartData() {
      axios
        .post("api/screen/order/report", {
          action: "product.cate.list",
          uuid: this.uuid,
          // uuid: "f8a9737f-edf7-3fd2-bc61-5318ca8705ff",
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          let datav = data.sort(this.toLarge);
          const target = datav.slice(0, 8).map((c) => {
            const { cate_name, rate, order_num } = c;
            return {
              name:
                cate_name.length > 3
                  ? cate_name.slice(0, 3) + "..."
                  : cate_name,
              value: rate,
            };
          });
          const isFont = this.$route.query.type;
          this.chart = this.$echarts.init(document.getElementById("right2"));
          this.chart.setOption({
            color: [
              "#8ACE7D",
              "#F9CB66",
              "#F06869",
              "#73BEDC",
              "#2DA375",
              "#FE875A",
              "#9E5BB0",
              "#5B6AC2",
            ],
            tooltip: {
              trigger: "item",
              // formatter: "{b}: {c} <br />({d}%)",
            },
            legend: {
              orient: "vertical",
              top: "center",
              right: 10,
              textStyle: {
                color: "#fff",
              },
              formatter: function(name) {
                // 获取legend显示内容
                let total = 0;
                let tarValue = 0;
                for (let i = 0, l = target.length; i < l; i++) {
                  total += target[i].value;
                  if (target[i].name == name) {
                    tarValue = target[i].value;
                  }
                }
                let p = ((tarValue / total) * 100).toFixed(1);
                return name + " " + p + "%";
              },
            },
            series: [
              {
                type: "pie",
                // radius: ["85%", "55%"],
                radius: ["30%", "100%"],
                center: ["30%", "50%"],
                data: target,
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            ],
          });
        });
    },
    // 获取表格的数据
    getTableList() {
      axios
        .post("api/screen/order/report", {
          action: "standing.get",
          uuid: this.uuid,
          type: this.area1StandingType,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          const target = data.map((c) => {
            const { value1, value2, value3, value4, value5 } = c;
            let aa = value5 ? value4 + value5 : value4;
            let time = formatTime(value1 * 1000, "MM-dd");
            return [time, value2, value3, aa];
          });
          this.config.data = target;

          let tabHead =
            this.area1StandingType == 1
              ? this.standBookHead1
              : this.area1StandingType == 2
              ? this.standBookHead2
              : this.area1StandingType == 3
              ? this.standBookHead3
              : this.area1StandingType == 4
              ? this.standBookHead4
              : this.standBookHead5;

          this.config = {
            data: target,
            header: tabHead,
            rowNum: 5, //表格行数
            headerHeight: 35,
            headerBGC: "rgb(4, 7, 138, 0)", //表头
            oddRowBGC: "rgb(4, 7, 138, 0)", //奇数行
            evenRowBGC: "rgb(4, 7, 138, 0)", //偶数行
            align: ["center", "center", "center", "center", "center"],
          };
        });
    },
  },
};
</script>

<style lang="scss">
.right2 {
  padding: 0.5rem 0.2rem 0.3rem;
  height: 3.25rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  overflow: hidden;
  background: url("../../assets/list_bac.png") no-repeat;
  background-size: 100% 100%;
  .dv-scroll-board .header {
    border: 1px solid #4a60fb !important;
    color: #64d6ff !important;
    background: transparent !important;
  }

  .ms_title {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
  }

  .ms_title::before {
    content: " ";
    display: inline-block;
    width: 0.05rem;
    height: 0.15rem;
    margin-right: 0.1rem;
    background-color: #64ffa3;
  }
}
.right2 .body-box {
  overflow: hidden;
}
</style>
