<template>
  <div id="left3">
    <div class="bg-color-black">
      <div v-if="market_id != 2380">
        <!-- 列表 -->
        <div v-if="is_on_sale_type == 1 && market_id != 2380">
          <span class="mx-2 ms_title" style="font-size:0.25rem">
            特价公示
          </span>

          <div class="d-flex jc-center body-box">
            <dv-scroll-board
              :config="config"
              style="height:2.2rem;margin:0 auto;"
            />
          </div>
        </div>

        <!--轮播图-->
        <div
          style="margin:0 auto;width:5rem;height:2.6rem;overflow:hidden;"
          v-if="is_on_sale_type == 2"
        >
          <image-tip
            :swiperData="on_sale_image"
            :imgWidth="imgWidth"
            :imgHeight="imgHeight"
          />
        </div>
        <!--视频-->
        <div v-if="is_on_sale_type == 3">
          <video-player
            :options="videoOptions"
            style="overflow: hidden;height:2.6rem;"
          />
        </div>
      </div>

      <div
        v-else
        style="margin:0 auto;width:5rem;height:2.6rem;overflow:hidden;"
      >
        <span class="mx-2 ms_title" style="font-size:0.25rem">
          监控抓拍
        </span>
        <img
          :src="capture_url"
          style="margin:0 auto;width:5rem;height:2.6rem;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ImageTip from "@/components/echart/image";
import VideoPlayer from "@/components/echart/video";

export default {
  props: {
    area1ShowType: {
      default: 1,
      type: Number,
    },
    area1Image: {
      default: [],
      type: Array,
    },
    area1Video: {
      default: "",
      type: String,
    },
    market_id: {
      default: 1,
      type: Number,
    },
    isInter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uuid: "",
      config: {},
      is_on_sale_type: "",
      on_sale_image: [],
      imgWidth: "5rem",
      imgHeight: "2.6rem",
      videoOptions: {
        autoplay: "muted", //自动播放
        controls: true, //用户可以与之交互的控件
        loop: true, //视频一结束就重新开始
        muted: false, //默认情况下将使所有音频静音
        aspectRatio: "16:9", //显示比率
        fullscreen: {
          options: { navigationUI: "hide" },
        },
        sources: [
          {
            // src: require("@/assets/1623139115688185.mp4"),
            // src: "http://test01.rongyi.so/5e61abed135f47651fdfe156061f21ce.mp4",
            src: "",
            type: "video/mp4",
          },
        ],
      },
      capture_url: "",
    };
  },
  components: { ImageTip, VideoPlayer },
  mounted() {
    // this.getList();
    this.is_on_sale_type = this.area1ShowType;
    this.on_sale_image = this.area1Image;
    this.videoOptions.sources[0].src = this.area1Video;
    // this.timer();
    this.init();
    this.getCaptureImg();
    setInterval(() => {
      setTimeout(() => {
        this.getList();
        this.getCaptureImg();
      }, 0);
    }, 43200000);
  },
  methods: {
    // timer() {
    //   return setInterval(() => {
    //     this.getList();
    //     this.getCaptureImg();
    //   }, 43200000);
    // },
    async init() {
      if (window.android && !this.isInter) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
      // this.getMarketTitle();
    },
    getList() {
      axios
        .post("api/screen/order/report", {
          action: "cate.list",
          limit: 10,
          uuid: this.uuid,
          // uuid: "55b08c70-7f9d-3931-82fc-fddde02e3932",
        })
        .then((res) => {
          const isFont = this.$route.query.type;
          const {
            data: { data },
          } = res;
          const target = data.map((c) => {
            const { category, unit_name, price_range_e, price_range_s } = c;
            return isFont == 2
              ? [
                  '<span style="font-size:0.3rem">' + category + "</span>",
                  '<span style="font-size:0.3rem">' + unit_name + "</span>",
                  '<span style="font-size:0.3rem">' + price_range_e + "</span>",
                  '<span style="font-size:0.3rem">' + price_range_s + "</span>",
                ]
              : [category, unit_name, price_range_e, price_range_s];
          });
          this.config.data = target;
          this.config = {
            data: target,
            header: ["商品", "单位", "原价", "现价"],
            // index: true,
            rowNum: isFont == 2 ? 3 : 5, //表格行数
            headerHeight: isFont == 2 ? 30 : 35,
            headerBGC: "rgb(4, 7, 137, 0)", //表头
            oddRowBGC: "rgb(4, 7, 138, 0)", //奇数行
            evenRowBGC: "rgb(4, 7, 138, 0)", //偶数行
            columnWidth: [100],
            align: ["center", "center", "center", "center", "center"],
          };
        });
    },
    // getMarketTitle() {
    //   axios
    //     .post("api/screen/client/main", {
    //       action: "market.common.get",
    //       uuid: this.uuid,
    //       // uuid: "f8a9737f-edf7-3fd2-bc61-5318ca8705ff",
    //     })
    //     .then((res) => {
    //       this.is_on_sale_type = res.data.data.is_on_sale_type;
    //       this.videoOptions.sources[0].src = res.data.data.on_sale_video;
    //       this.on_sale_image = res.data.data.on_sale_image;

    //       // this.market_name = res.data.data.market_name;
    //       // this.notice = res.data.data.notice;
    //     });
    // },
    //获取截图
    getCaptureImg() {
      axios
        .post("api/screen/monitor/screenshot", {
          action: "get.image",
          uuid: this.uuid,
          // uuid: "f8a9737f-edf7-3fd2-bc61-5318ca8705ff",
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.capture_url = res.data.data.image_url;
          }
          // this.market_name = res.data.data.market_name;
          // this.notice = res.data.data.notice;
        });
    },
  },
};
</script>

<style lang="scss">
#left3 {
  padding: 0.5rem 0.2rem 0.3rem;
  height: 3.25rem;
  min-width: 5rem;
  // width: 5rem;
  border-radius: 0.0625rem;
  background: url("../../assets/list_bac.png") no-repeat;
  background-size: 100% 100%;
  .dv-scroll-board .header {
    border: 1px solid #4a60fb !important;
    color: #64d6ff !important;
    background: transparent !important;
  }

  .ms_title {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
  }

  .ms_title::before {
    content: " ";
    display: inline-block;
    width: 0.05rem;
    height: 0.15rem;
    margin-right: 0.1rem;
    background-color: #64ffa3;
  }

  .bg-color-black {
    // overflow: hidden;
    height: 2.6rem;
  }
  .dv-scroll-board .header {
    border: 1px solid #4a60fb;
    color: #64d6ff;
  }
  .text {
    height: 0.3rem;
    line-height: 0.3rem;
    color: #c3cbde;
  }
  .body-box {
    overflow: hidden;
  }
}
</style>
