import request from "@/utils/request";

//数据屏获取模板
export function screenTemplateServe(data) {
  return request({
    url: "/api/screen/client/main",
    method: "post",
    data,
  });
}
