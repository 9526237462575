<template>
  <div id="mid2">
    <div class="bg-color-black">
      <span
        class="fs-xl  mx-2 ms_title"
        style="font-size:0.25rem"
        v-if="area1ShowType != 2"
        >{{
          area1ShowType == 4
            ? standBook[area1StandingType - 1].name
            : "质检公示"
        }}</span
      >

      <!--轮播广告-->
      <div
        style="width:5.6rem;height:2.6rem;margin:0 auto;overflow: hidden;"
        v-if="area1ShowType == 2"
      >
        <image-tip
          :swiperData="area1Image"
          :imgWidth="imgWidth"
          :imgHeight="imgHeight"
        />
      </div>
      <!--质检公示与记录的table-->
      <div
        class="d-flex jc-center body-box"
        v-if="area1ShowType == 1 || area1ShowType == 4"
      >
        <dv-scroll-board
          :config="config"
          style="width:5.4rem;height:2.2rem;margin:0 auto;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";
import ImageTip from "@/components/echart/image";

export default {
  props: {
    area1Image: {
      default: [],
      type: Array,
    },
    area1StandingType: {
      default: 1,
      type: Number,
    },
    area1ShowType: {
      default: 1,
      type: Number,
    },
    area1_video: {
      default: "",
      type: String,
    },
    isInter: {
      type: Boolean,
      default: false,
    },
  },
  components: { ImageTip },
  data() {
    return {
      uuid: "",
      config: {},
      imgWidth: "5.6rem",
      imgHeight: "2.6rem",
      standBook: [
        { id: 1, name: "质检记录" },
        { id: 2, name: "进货记录" },
        { id: 3, name: "消杀记录" },
        { id: 4, name: "消毒记录" },
        { id: 5, name: "衡器检修" },
      ],
      standBookHead1: ["时间", "品名", "检测项目", "检测结果"],
      standBookHead2: ["时间", "进场品名", "商户", "进场数量"],
      standBookHead3: ["时间", "消毒区域", "消毒方式", "操作人"],
      standBookHead4: ["时间", "消毒区域", "消杀方式", "操作人"],
      standBookHead5: ["时间", "设备号", "商户", "设备厂家"],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    setInterval(() => {
      setTimeout(() => {
        this.getList();
      }, 0);
    }, 600000);
  },
  methods: {
    timer() {
      return setInterval(() => {
        this.getList();
      }, 600000);
    },
    async init() {
      if (window.android && !this.isInter) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
    },
    getList() {
      if (this.area1ShowType == 1) {
        this.getQuilityList();
      } else if (this.area1ShowType == 4) {
        this.getTableList();
      }
    },
    // 获取质检公示的表格的数据
    getQuilityList() {
      axios
        .post("api/screen/order/report", {
          action: "quality.list",
          limit: 10,
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          const isFont = this.$route.query.type;
          const target = data.data.map((c) => {
            const {
              orgz_name,
              floor_code,
              product_name,
              c_name,
              is_qualified,
            } = c;
            const d = is_qualified === 0 ? "不合格" : "合格";
            return isFont == 2
              ? [
                  '<span style="font-size:0.3rem">' +
                    orgz_name.slice(0, 3) +
                    "</span>",
                  '<span style="font-size:0.3rem">' +
                    floor_code.slice(0, 3) +
                    "</span>",
                  '<span style="font-size:0.3rem">' +
                    product_name.slice(0, 2) +
                    "</span>",
                  '<span style="font-size:0.3rem">' +
                    c_name.slice(0, 2) +
                    "</span>",
                  '<span style="font-size:0.3rem">' + d.slice(0, 2) + "</span>",
                ]
              : [
                  '<span style="font-size:0.2rem">' + orgz_name + "</span>",
                  '<span style="font-size:0.2rem">' + floor_code + "</span>",
                  '<span style="font-size:0.2rem">' + product_name + "</span>",
                  '<span style="font-size:0.2rem">' + c_name + "</span>",
                  '<span style="font-size:0.2rem">' + d + "</span>",
                ];
          });
          this.config.data = target;
          this.config = {
            data: target,
            header:
              isFont == 2
                ? [
                    '<span style="font-size:0.3rem">商户</span>',
                    '<span style="font-size:0.3rem">铺位号</span>',
                    '<span style="font-size:0.3rem">商品</span>',
                    '<span style="font-size:0.3rem">检查项</span>',
                    '<span style="font-size:0.3rem">结果</span>',
                  ]
                : ["商户", "铺位号", "商品", "检查项", "结果"],
            rowNum: isFont == 2 ? 3 : 5, //表格行数
            headerHeight: this.FontChart(0.4),
            headerBGC: "rgb(4, 7, 138, 0)", //表头
            oddRowBGC: "rgb(4, 7, 138, 0)", //奇数行
            evenRowBGC: "rgb(4, 7, 138, 0)", //偶数行
            // columnWidth: [100],
            align: ["center", "center", "center", "center", "center"],
          };
        });
    },
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      return res * fontSize;
    },
    // 获取记录的表格的数据
    getTableList() {
      axios
        .post("api/screen/order/report", {
          action: "standing.get",
          uuid: this.uuid,
          type: this.area1StandingType,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          const target = data.map((c) => {
            const { value1, value2, value3, value4, value5 } = c;
            let aa = value5 ? value4 + value5 : value4;
            let time = formatTime(value1 * 1000, "MM-dd");
            return [time, value2, value3, aa];
          });
          this.config.data = target;

          let tabHead =
            this.area1StandingType == 1
              ? this.standBookHead1
              : this.area1StandingType == 2
              ? this.standBookHead2
              : this.area1StandingType == 3
              ? this.standBookHead3
              : this.area1StandingType == 4
              ? this.standBookHead4
              : this.standBookHead5;

          this.config = {
            data: target,
            header: tabHead,
            rowNum: 5, //表格行数
            headerHeight: 35,
            headerBGC: "rgb(4, 7, 138, 0)", //表头
            oddRowBGC: "rgb(4, 7, 138, 0)", //奇数行
            evenRowBGC: "rgb(4, 7, 138, 0)", //偶数行
            align: ["center", "center", "center", "center", "center"],
          };
        });
    },
  },
};
</script>

<style lang="scss">
.dv-scroll-board .header {
  background-color: rgb(4, 7, 137);
  border: 1px solid #4a60fb;
  color: #64d6ff;
}
#mid2 {
  padding: 0.5rem 0.2rem 0.3rem;
  height: 3.2rem;
  min-width: 5rem;
  border-radius: 0.0625rem;
  background: url("../../assets/list_bac.png") no-repeat;
  background-size: 100% 100%;
  .dv-scroll-board .header {
    border: 1px solid #4a60fb !important;
    color: #64d6ff !important;
    background: transparent !important;
    // background-color: rgb(4, 7, 137) !important;
  }

  .ms_title {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
  }

  .ms_title::before {
    content: " ";
    display: inline-block;
    width: 0.05rem;
    height: 0.15rem;
    margin-right: 0.1rem;
    background-color: #64ffa3;
  }

  .bg-color-black {
    // overflow: hidden;
    height: 3rem;
    border-radius: 0.125rem;
  }

  .text {
    color: #c3cbde;
  }
  .body-box {
    overflow: hidden;
  }
}
</style>
