<template>
  <div id="right1_1">
    <span
      class="ms_title"
      style="font-size: 0.25rem"
      v-if="areaShowType != 2"
      >{{
        areaShowType == 4
          ? standBook[areaStandingType - 1].name
          : areaShowType == 8
          ? "今日业态销售排行"
          : "今日销售商品排行"
      }}</span
    >
    <div
      id="right1_1_echart"
      style="height: 2.7rem; width: 5.5rem"
      v-if="areaShowType == 1"
    ></div>
    <!--轮播广告-->
    <div
      style="width: 5.6rem; height: 2.5rem; margin: 0 auto; overflow: hidden"
      v-if="areaShowType == 2"
    >
      <image-tip
        :swiperData="areaImage"
        :imgWidth="imgWidth"
        :imgHeight="imgHeight"
      />
    </div>
    <!--记录-->
    <div
      class="d-flex jc-center body-box"
      style="overflow: hidden; height: 2.2rem"
      v-if="areaShowType == 4"
    >
      <dv-scroll-board
        :config="config"
        style="height: 2.2rem; margin: 0 auto"
      />
    </div>
    <!--倒三角-今日业态销售排行-->
    <div
      id="right1_1_echart2"
      style="height: 2.7rem; width: 5.5rem"
      v-if="areaShowType == 8"
    ></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import echarts from "echarts";
import axios from "axios";
import { formatTime } from "@/utils/index.js";
import ImageTip from "@/components/echart/image";

export default {
  props: {
    areaImage: {
      default: [],
      type: Array,
    },
    areaStandingType: {
      default: 1,
      type: Number,
    },
    areaShowType: {
      default: 1,
      type: Number,
    },
    area_video: {
      default: "",
      type: String,
    },
    isInter: {
      type: Boolean,
      default: false,
    },
  },
  components: { ImageTip },
  data() {
    return {
      uuid: "",
      parduct_name: [],
      config: {},
      imgWidth: "5.6rem",
      imgHeight: "2.5rem",
      standBook: [
        { id: 1, name: "质检记录" },
        { id: 2, name: "进货记录" },
        { id: 3, name: "消杀记录" },
        { id: 4, name: "消毒记录" },
        { id: 5, name: "衡器检修" },
      ],
      standBookHead1: ["时间", "品名", "检测项目", "检测结果"],
      standBookHead2: ["时间", "进场品名", "商户", "进场数量"],
      standBookHead3: ["时间", "消毒区域", "消毒方式", "操作人"],
      standBookHead4: ["时间", "消毒区域", "消杀方式", "操作人"],
      standBookHead5: ["时间", "设备号", "商户", "设备厂家"],
    };
  },
  mixins: [echartMixins],
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 7) {
        return value.slice(0, 7) + "...";
      }
      return value;
    },
  },

  mounted() {
    // this.timer();
    this.init();
    setInterval(() => {
      setTimeout(() => {
        this.getList();
      }, 0);
    }, 1800000);
  },
  methods: {
    destroyed() {
      window.onresize = null;
    },
    // timer() {
    //   return setInterval(() => {
    //     this.getList();
    //   }, 1800000);
    // },
    async init() {
      if (window.android && !this.isInter) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
    },
    getList() {
      if (this.areaShowType == 1) {
        this.getRechartData();
      } else if (this.areaShowType == 4) {
        this.getTableList();
      } else if (this.areaShowType == 8) {
        this.getRechartData2();
      }
    },
    getRechartData() {
      axios
        .post("api/screen/order/report", {
          action: "product.get",
          limit: 5,
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          this.parduct_name = data
            .slice(0, 4)
            .map((c) =>
              c.product_name.length > 2
                ? c.product_name.slice(0, 3)
                : c.product_name
            );
          this.sales_amount = data.map(
            (c) => c.sales_amount / (100).toFixed(2)
          );
          this.chart = this.$echarts.init(
            document.getElementById("right1_1_echart")
          );
          const isFont = this.$route.query.type;

          this.chart.setOption({
            xAxis: {
              type: "category",
              splitLine: { show: false },
              data: this.parduct_name,
              axisLabel: {
                show: true,
                interval: 0,
                // rotate:45,
                textStyle: {
                  // color: "#fff", //更改坐标轴文字颜色
                  fontSize:
                    isFont == 2 ? this.FontChart(0.35) : this.FontChart(0.2), //更改坐标轴文字大小
                  fontWeight: 400,
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  type: "solid",
                  color: "#fff", //左边线的颜色
                },
              },
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              type: "value",
              splitLine: { show: false },
              axisLine: {
                show: false,
                lineStyle: {
                  type: "solid",
                  color: "#fff", //左边线的颜色
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
                textStyle: {
                  color: "#c3dbff", //更改坐标轴文字颜色
                  fontSize: this.FontChart(0.2), //更改坐标轴文字大小
                },
                formatter: function(value, index) {
                  var value;
                  if (value >= 1000) {
                    value = value / 1000 + "k";
                  } else if (value < 1000) {
                    value = value;
                  }
                  return value;
                },
              },
            },
            series: [
              {
                data: this.sales_amount,
                type: "bar",
                barWidth: "30%",
                itemStyle: {
                  normal: {
                    //每根柱子颜色设置
                    color: function(params) {
                      let colorList = [
                        ["#FF8E8E", "#F70059"],
                        ["#F7E400", "#EB7534"],
                        ["#6CF6E3", "#009EF7"],
                        ["#8ED6FF", "#8ED6FF"],
                        ["#8ED6FF", "#8ED6FF"],
                        ["#8ED6FF", "#8ED6FF"],
                      ];
                      var index = params.dataIndex;
                      if (params.dataIndex >= colorList.length) {
                        index = params.dataIndex - colorList.length;
                      }
                      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: colorList[index][0],
                        },
                        {
                          offset: 1,
                          color: colorList[index][1],
                        },
                      ]);
                    },
                  },
                },
              },
            ],
          });
        });
    },
    getRechartData2() {
      axios
        .post("api/screen/order/report", {
          action: "categories.list",
          limit: 5,
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          let cate_name = data.map((c) => c.cate_name);
          this.chart = this.$echarts.init(
            document.getElementById("right1_1_echart2")
          );
          let handleData = data.slice(0, 5).map((ele, index) => {
            return {
              value:
                index == 0
                  ? 50
                  : index == 1
                  ? 40
                  : index == 2
                  ? 30
                  : index == 3
                  ? 20
                  : 10,
              name:
                ele.cate_name.length > 4
                  ? ele.cate_name.slice(0, 3) + "..."
                  : ele.cate_name,
            };
          });
          const isFont = this.$route.query.type;

          this.chart.setOption({
            title: {
              text: "",
            },
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b} : {c}%",
            },
            color: ["#FF4B4B", "#FF9629", "#FEE501", "#50C84A", "#55EFB1"],
            legend: {
              orient: "vertical",
              top: "center",
              right: 0,
              textStyle: {
                color: "#fff",
              },
            },
            series: [
              {
                name: "",
                type: "funnel",
                left: "0",
                top: "middle",
                // bottom: 60,
                width: "80%",
                min: 0,
                max: 50,
                minSize: "0%",
                maxSize: "100%",
                sort: "descending",
                gap: 0,
                height: "180",
                label: {
                  show: false,
                },
                labelLine: {
                  length: 10,
                },
                itemStyle: {
                  borderWidth: 0,
                },
                emphasis: {
                  label: {
                    fontSize: 12,
                  },
                },
                data: handleData,
              },
            ],
          });
        });
    },
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      return res * fontSize;
    },
    // 获取表格的数据
    getTableList() {
      axios
        .post("api/screen/order/report", {
          action: "standing.get",
          uuid: this.uuid,
          type: this.areaStandingType,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          const target = data.map((c) => {
            const { value1, value2, value3, value4, value5 } = c;
            let aa = value5 ? value4 + value5 : value4;
            let time = formatTime(value1 * 1000, "MM-dd");
            return [time, value2, value3, aa];
          });
          this.config.data = target;

          let tabHead =
            this.areaStandingType == 1
              ? this.standBookHead1
              : this.areaStandingType == 2
              ? this.standBookHead2
              : this.areaStandingType == 3
              ? this.standBookHead3
              : this.areaStandingType == 4
              ? this.standBookHead4
              : this.standBookHead5;

          this.config = {
            data: target,
            header: tabHead,
            rowNum: 5, //表格行数
            headerHeight: 35,
            headerBGC: "rgb(4, 7, 138, 0)", //表头
            oddRowBGC: "rgb(4, 7, 138, 0)", //奇数行
            evenRowBGC: "rgb(4, 7, 138, 0)", //偶数行
            align: ["center", "center", "center", "center", "center"],
          };
        });
    },
  },
};
</script>

<style lang="scss">
#right1_1 {
  padding: 0.5rem 0.2rem 0.3rem;
  height: 3.25rem;
  min-width: 5rem;
  border-radius: 0.0625rem;
  overflow: hidden;
  background: url("../../assets/list_bac.png") no-repeat;
  background-size: 100% 100%;
  .dv-scroll-board .header {
    border: 1px solid #4a60fb !important;
    color: #64d6ff !important;
    background: transparent !important;
    // background-color: rgb(4, 7, 137) !important;
  }

  .ms_title {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
  }

  .ms_title::before {
    content: " ";
    display: inline-block;
    width: 0.05rem;
    height: 0.15rem;
    margin-right: 0.1rem;
    background-color: #64ffa3;
  }
}
.dv-scroll-board .row .row-item {
  height: 30px !important;
  line-height: 30px !important;
}
</style>
